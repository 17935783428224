var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {},
      [
        _c(
          "el-popover",
          {
            staticClass: "popover_title",
            attrs: {
              placement: "bottom",
              title: "表头数据展示设置",
              trigger: "click",
              width: "188",
            },
            on: { show: _vm.screeningShow, hide: _vm.screeningHide },
          },
          [
            _c(
              "div",
              { staticStyle: { width: "85%" } },
              [
                _c(
                  "el-checkbox-group",
                  {
                    attrs: {
                      size: "mini",
                      min: _vm.min,
                      max: _vm.checkBoxGroup.length,
                    },
                    on: { change: _vm.selectChange },
                    model: {
                      value: _vm.checkedColumnList,
                      callback: function ($$v) {
                        _vm.checkedColumnList = $$v
                      },
                      expression: "checkedColumnList",
                    },
                  },
                  [
                    _c(
                      "draggable",
                      _vm._b(
                        { attrs: { list: _vm.checkBoxGroup } },
                        "draggable",
                        _vm.dragOptions,
                        false
                      ),
                      _vm._l(_vm.checkBoxGroup, function (item, index) {
                        return _c(
                          "el-checkbox",
                          { key: index, attrs: { label: index } },
                          [
                            _c("img", {
                              attrs: { src: _vm.FromIcon, alt: "" },
                            }),
                            _vm._v(_vm._s(item.label)),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                attrs: { slot: "reference", title: "隐藏列" },
                slot: "reference",
              },
              [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.screeningNum != 0,
                      expression: "screeningNum != 0",
                    },
                  ],
                  style: _vm.selCls,
                  attrs: {
                    src: require("@/views/financialStatements/Report/img/screening.png"),
                    alt: "",
                  },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.screeningNum == 0,
                      expression: "screeningNum == 0",
                    },
                  ],
                  style: _vm.selCls,
                  attrs: {
                    src: require("@/views/financialStatements/Report/img/screeningQ.png"),
                    alt: "",
                  },
                }),
              ]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }