<template>
  <div>
    <div class="">
      <el-popover
        placement="bottom"
        class="popover_title"
        title="表头数据展示设置"
        trigger="click"
        @show="screeningShow"
        @hide="screeningHide"
        width="188"
      >
        <div style="width: 85%">
          <el-checkbox-group v-model="checkedColumnList" size="mini" :min="min" :max="checkBoxGroup.length" @change="selectChange">
            <draggable :list="checkBoxGroup" v-bind="dragOptions">
              <el-checkbox
              v-for="(item, index) in checkBoxGroup"
              :key="index"
              :label="index"
              ><img :src="FromIcon" alt="">{{ item.label }}</el-checkbox
            >
            </draggable>


          </el-checkbox-group>
        </div>
        <div title="隐藏列" slot="reference">
          <img
            v-show="screeningNum != 0"
            :style="selCls"
            src="@/views/financialStatements/Report/img/screening.png"
            alt=""
          />
          <img
            v-show="screeningNum == 0"
            :style="selCls"
            src="@/views/financialStatements/Report/img/screeningQ.png"
            alt=""
          />
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: { draggable },
  props: {
    selCls: {
      type: String,
      default: "width: 35px;height: 40px;"
    },
    // 列-列表
    checkBoxGroup: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // 初始化的隐藏key
    checkedColumns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    reportType: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 1,
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "sortTableData",
        ghostClass: "sortable-ghost", // 设置拖动元素的class的占位符的类名。
        draggable: ".el-checkbox1", // 拖动元素
      };
    },
  },
  watch: {
    checkedColumns(val) {
      this.checkedColumnList = this.checkedColumns;
    },
    checkBoxGroup(val) {
      this.$emit("sortChange", val);
    }
  },
  data() {
    return {
      FromIcon: require("./FromIcon.png"),
      screeningNum: 0,
      checkedColumnList: this.checkedColumns,
      classData: "width: 35px;height: 40px;"
    };
  },
  methods: {
    selectChange(val) {
      let dataConlum = val.join();
      this.$axios
        .post("/acb/2.0/reportFieldHide/save", {
          data: {
            reportType: this.reportType,
            hideFields: dataConlum,
          },
        })
        .then((res) => {
          this.$emit("selectChange", val);
        });
    },
    screeningShow() {
      this.screeningNum = 1;
    },
    screeningHide() {
      this.screeningNum = 0;
    },
  },
  created() {},
};
</script>
<style>
</style>
<style scoped lang="stylus" rel="stylesheet/stylus">
.selCls {
  width: 45px;
  height: 54px;
}
::v-deep .el-popover {
  padding: 12px

}

::v-deep .el-checkbox {
  height: 22px;
  line-height: 22px
  img {
    position relative
    top 2px
    right: 8px
  }
  &:first-child {
    margin-top: 4px;
  }
  margin-top: 16px
  .el-checkbox__input {
    position relative
    right: -176px
  }
  .el-checkbox__label {
    padding-left: 0px;
  }
}
</style>
